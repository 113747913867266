import React, { useEffect, useState } from "react"
import Layout from "@components/layout"
import { Button, Col, Row } from "reactstrap"
import { useAuth } from "../../auth/useAuth"
import { isFuture, isPast, isToday } from "date-fns"
import { Link, navigate } from "gatsby"
import { formatInTimeZone } from "date-fns-tz"

export const Head = () => {
  return <title>Socials</title>
}

const Socials = () => {
  const { makeRequest } = useAuth()

  const [socials, setSocials] = useState(null)

  const [past, setPast] = useState(false)

  const updateSocials = async () => {
    const res = await makeRequest("socials", null, "GET")
    setSocials(res?.socials ?? [])
  }

  useEffect(() => {
    updateSocials()
  }, [])

  return (
    <>
      <Layout title={"Socials"} progress={socials === null}>
        <Row>
          <Col className={"text-right"} sm={12}>
            <div className={"d-flex align-items-center"}>
              {socials?.length > 0 ? (
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className={`btn ${past ? "btn-light" : "btn-primary"}`}
                    onClick={() => setPast(false)}
                  >
                    Upcoming
                  </button>
                  <button
                    type="button"
                    className={`btn ${!past ? "btn-light" : "btn-primary"}`}
                    onClick={() => setPast(true)}
                  >
                    Past
                  </button>
                </div>
              ) : (
                <p className={"m-0"}>No socials at the moment</p>
              )}

              <Button
                color={"success"}
                className={"ml-auto "}
                style={{ minWidth: "50px" }}
                onClick={() => navigate("/socials/manage")}
              >
                +
              </Button>
            </div>
          </Col>
          <Col lg={12}>
            {socials?.length ? (
              <List list={socials} past={past} updateList={updateSocials} />
            ) : null}
          </Col>
        </Row>
      </Layout>
    </>
  )
}
export default Socials

const List = ({ list = [], past = false, updateList = () => null }) => {
  const [progress, setProgress] = useState(false)

  const { makeRequest } = useAuth()

  const filteredList = list.filter((s) => {
    const date = new Date(s.startDateTime)
    if (past) {
      return isPast(date) && !isToday(date)
    } else {
      return isFuture(date) || isToday(date)
    }
  })

  const deleteItem = async (id) => {
    let text = "You are about to delete a Social!\nPress OK to confirm."
    if (window.confirm(text) !== true) {
      return
    }
    setProgress(true)
    await makeRequest("socials", { id }, "delete")
    await updateList()
    setProgress(false)
  }

  if (!filteredList?.length) {
    return <p className={"mt-5"}>No items yet.</p>
  }
  return (
    <>
      <table className="table table-hover table-striped mt-5">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
            {!past && <th />}
          </tr>
        </thead>

        <tbody>
          {filteredList.map((s) => {
            return (
              <tr key={s.id}>
                <td>{s.title}</td>
                <td>{s.description}</td>
                <td>
                  {formatInTimeZone(
                    new Date(s.startDateTime),
                    "America/Toronto",
                    "yyyy MMM dd, h:mm a"
                  )}{" "}
                  -{" "}
                  {formatInTimeZone(
                    new Date(s.endDateTime),
                    "America/Toronto",
                    "h:mm a"
                  )}
                </td>
                {!past && (
                  <td>
                    <p className="m-0 d-flex align-items-center justify-content-end">
                      <Link
                        to={`/socials/manage?id=${s.id}`}
                        state={{ social: s }}
                        className="btn px-2 btn-link btn-text text-warning"
                        style={{
                          minWidth: "20px",
                          height: "20px",
                          lineHeight: "20px",
                        }}
                      >
                        Edit
                      </Link>
                      |
                      <button
                        className="btn px-2 btn-link btn-text text-danger"
                        style={{
                          minWidth: "20px",
                          height: "20px",
                          lineHeight: "20px",
                        }}
                        disabled={progress}
                        onClick={() => {
                          deleteItem(s.id)
                        }}
                      >
                        Delete
                      </button>
                    </p>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
